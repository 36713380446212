<script lang="ts">
export type GDropdownOption = {
  key: string | number
  value: string | number
}

export type GDropdownOptions = GDropdownOption[]
</script>

<script lang="ts" setup>import { computed as _computed } from 'vue';

import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import { useElementSize } from '@vueuse/core'
import { trackEvent } from '@shared/tracking'
import { isPlainObject } from '@shared/object'

withDefaults(defineProps<{
  name: string
  label?: string
  hint?: string
  modelValue?: any
  options: Array<any>
  optionValue?: string
  optionLabel?: string
  icon?: string
  scrollable?: boolean
  type?: string

  // Whether the whole options should be propagated on a change event.
  // This also determines whether what is received/stored when using v-model.
  wholeOption?: boolean
}>(), { optionValue: 'value',optionLabel: 'label',scrollable: true, })

const emit = defineEmits<{ 'update:modelValue': [value: any] }>()

const listboxContainer = ref<HTMLElement>()
const { width } = useElementSize(listboxContainer)

const selectedOption = _computed({
  get () {
    const selectedValue = valueForOption(__props.modelValue)
    return __props.options.find(o => valueForOption(o) === selectedValue)
  },
  set (option: any) {
    emit('update:modelValue', __props.wholeOption ? option : valueForOption(option))
  },
})

const onUpdateModelValue = (selected: any) => {
  trackEvent(__props.name, selected, 'checked')
}

const scrollableOptions = computed(() => {
  if (__props.scrollable) {
    return { overflowY: 'auto', maxHeight: '300px' }
  }
})

function valueForOption (option: any) {
  return isPlainObject(option) ? option[__props.optionValue] : option
}

function labelForOption (option: any) {
  return isPlainObject(option) ? option[__props.optionLabel] : option
}
</script>

<template>
  <Listbox id="listbox" v-model="selectedOption" as="div" class="!leading-none" @update:modelValue="onUpdateModelValue">
    <div ref="listboxContainer">
      <ListboxLabel v-if="label" class="block font-semibold text-navy mb-2.5"><span v-html="label"/>
        <span v-if="hint" class="font-normal text-sm text-v2-tertiary ml-2" v-html="hint"/>
      </ListboxLabel>
      <Float placement="bottom-start" :offset="4" flip>
        <div>
          <ListboxButton
            v-slot="{ open }"
            class="
              relative cursor-default text-left
              w-full p-5 block
              outline-none border-solid border-1 border-grey-primary rounded-v2md
              focus:border-primary-base
            "
          >
            <span v-if="icon" class="absolute inset-y-0 left-3 flex items-center pr-2 pointer-events-none">
              <GIcon :name="icon" aria-hiden="true" color="v2-tertiary"/>
            </span>
            <span v-if="selectedOption" :class="['block truncate', icon ? 'px-5' : null]">
              {{ labelForOption(selectedOption) }}
            </span>
            <span v-else class="text-v2-tertiary" :class="icon && 'px-5'">
              Please Choose One
            </span>
            <span class="absolute inset-y-0 right-3 flex items-center pr-2 pointer-events-none">
              <GIcon v-if="open" name="carousel-up" aria-hiden="true" color="v2-tertiary"/>
              <GIcon v-else name="carousel-down" aria-hiden="true" color="v2-tertiary"/>
            </span>
          </ListboxButton>
        </div>
        <Transition
          leaveActiveClass="transition ease-in duration-100"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
        >
          <ListboxOptions
            class="
              relative w-full
              bg-white rounded-v2md shadow-v2md py-1 overflow-auto
              focus:outline-none border border-primary-base
            "
            :style="{ width: `${width}px`, ...scrollableOptions}"
          >
            <ListboxOption
              v-for="option in options"
              :key="valueForOption(option)"
              v-slot="{ active, selected }"
              as="template"
              :value="option"
            >
              <li
                :class="active ? 'text-navy bg-surface-grey' : 'text-v2-secondary'"
                class="cursor-pointer select-none relative py-4 px-4"
              >
                <span class="block truncate">
                  {{ labelForOption(option) }}
                </span>

                <span
                  v-if="selected"
                  class="absolute inset-y-0 right-0 flex items-center pr-4"
                  :class="active ? 'text-white' : 'text-indigo-600'"
                />
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Transition>
      </Float>
    </div>
  </Listbox>
</template>
